import React from 'react';
import Banner from '../components/Banner/Banner';
import CoinsTable from '../components/CoinsTable';
import Footer from '../components/Footer';

const HomePage = () => {
    return <>
    <Banner />
    <CoinsTable />
    <Footer />
    </>
    
};

export default HomePage;
